import React, {lazy, Suspense} from 'react'
import ToastList from './toast-list/toast-list.component.js'

export default function ToastsRoot() {
  return (
    <Suspense fallback={null}>
      <ToastList />
    </Suspense>
  )
}
