import React from 'react'
import {Scoped, a, m, t} from 'kremling'
import { toastCss as css } from '../toast-css.js'
import CprButton from '../../cpr-button/cpr-button.component.js'
import CprIcon from '../../cpr-icon/cpr-icon.component.js'

const toastNameToIconName = {
  warning: 'sm-error',
  info: 'sm-information',
  success: 'sm-check',
}

export default function Toast (props) {
  const {
    toast,
    position = 'top',
    onBlur,
    onFocus,
    close,
    // limited use the toast service doesn't support this and may never support this.
    // Added for use with the offline listener/parcel
    allowManualClose = true
  } = props

  return (
    <Scoped css={css}>
      <div
        className={
          a("toast")
            .m("bottom", position === 'bottom')
            .m("middle", position === 'middle')
            .m("top", position === 'top')
        }
      >
        <div className="toast-left">
          <div className={`toast-type-icon ${toast.type}`}>
            <CprIcon
              name={toastNameToIconName[toast.type]}
              size={24}
            />
          </div>
          <div className="toast-text">
            {toast.headerText &&
              <div className="cps-body cps-wt-bold">
                {toast.headerText}
              </div>
            }
            {toast.message}
            {toast.links.map(link => (
              <div className="toast-link">
                <a href={link.url}>
                  {link.label}
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className="toast-right">
          <div className="toast-buttons">
            {toast.actionCallback && toast.actionText &&
                <CprButton
                  actionType="unstyled"
                  type="button"
                  onClick={evt => handleActionClick(evt, toast)}
                  className="custom-action"
                  onBlur={onBlur}
                  onFocus={onFocus}
                  tabIndex={0}
                >
                  {toast.actionText}
                </CprButton>
            }
            {
              allowManualClose && (
                <div className="close-button">
                  <CprButton
                    actionType="unstyled"
                    type="button"
                    onClick={() => close(toast.id)}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    tabIndex={0}
                  >
                    <CprIcon
                      name="close"
                      size={18}
                    />
                  </CprButton>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </Scoped>
  )

  function handleActionClick(evt, toast) {
    toast.actionCallback()
    close(toast.id)
  }
}
