import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import Toast from './toast.component.js'

// Single spa parcel functions
export default singleSpaReact({
  React,
  ReactDOM,
  rootComponent: ToastParcel,
});

function ToastParcel (props) {
  return (
    <ErrorBoundary>
      <Toast {...props}/>
    </ErrorBoundary>
  )
}

// generic error boundary so we don't cause any issues
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(err, info) {
    SystemJS.import('sentry-error-logging!sofe')
      .then(([errorLogging]) => {
        errorLogging.captureException(err)
      })
  }

  render() {
    if (this.state.hasError) {
      return null
    }

    return this.props.children;
  }
}
