import React, {lazy, Suspense} from 'react'

// The toast service is loaded so early in the app's initialization process that it is often executed before canopy-styleguide is available.
// There is a bug in SystemJS 0.21 where the circular reference between toast-service/canopy-styleguide/other util services
// cause the whole app not to load. By eliminating the synchronous dependency on canopy-styleguide, we can avoid
// that race conditiony bug in SystemJS that happens with some circular references.
//
// Additionally, react's lazy function only works with default exports, not with named exports. Which is super unfortunate.
// So I have to fool react into thinking that CprIcon and CprButton are default exports when they actually aren't.
const ActualCprIcon = lazy(() => SystemJS.import('canopy-styleguide!sofe').then(cs => ({default: cs.CprIcon})))

export default function CprIcon(props) {
  return (
    <Suspense fallback={null}>
      <ActualCprIcon {...props}/>
    </Suspense>
  )
}
